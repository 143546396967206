import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-repeat: repeat-y;
  background-position: center top;
  background-size: cover;
`;

export const TextTitle = styled.p`
  color: White;
  font-family: "coder";
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  text-shadow: 0 0 15px #b150fe;
`;

export const TextTitle2 = styled.p`
  color: white;
  font-family: "pp";
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
`;

export const TextSubTitle = styled.p`
  color: white;
  font-family: "pp";
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
`;

export const TextDescription = styled.p`
  color: white;
  font-family: "pp";
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
`;

export const TEXTHEAD = styled.p`
  color: white;
  font-family: "coder";
  font-size: 1.875rem;
  line-height: 2.25rem;
`;

export const TEXTTITLES = styled.p`
color: #000000;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 5px;
    @media (max-width: 565px) {
      font-size: 0.75rem;
      letter-spacing: 2px;
    }
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const HeaderDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;

`;

export const FooterDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
align-content: flex-start;
@media (max-width: 565px) {
  margin-bottom: 88px;
}
`;

export const IMGCT = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 25px;
grid-row-gap: 30px;
align-items: center;
justify-content: center;
padding: 20px;
width: 70%;
border: 2px solid rgb(177 80 253);
border-radius: 1rem;
@media (max-width: 565px) {
  grid-template-columns: repeat(2,1fr);
}
`;

export const TOKENSELECT = styled.div`
cursor: pointer;
:hover {
  border: 2px solid black;
}
`

export const BTNDIV = styled.div`
width: 30%;
@media (max-width: 565px) {
  width: 80%
}
`

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  font-family: "pp";
  margin-right: 20px;
  cursor: pointer;
  font-style: italic;
  :hover {
    color: #1675e0;
    opacity: 0.7;
  }
  @media (max-width: 565px) {
    margin-right: 0.75rem;
    margin-top: 1rem;
  }
`;

export const StyledLinkFooter = styled.a`
color: white;
  text-decoration: none;
  font-family: "pp";
  font-size: 1.125rem;
  line-height: 1.75rem;
  cursor: pointer;
  :hover {
    color: #1675e0;
    opacity: 0.7;
  }
  @media (max-width: 565px) {
    margin-right: 0.75rem;
    margin-top: 1rem;
  }
`;

export const Icons = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      filter: drop-shadow(-4px 5px 0px black);
      filter: hue-rotate(75deg);
    }
`;


export const Headerlinks = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 565px) {
      margin-top: 10px;
      margin-bottom: 30px;
    }
`;

export const MContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 64rem;
  border: 2px solid rgb(177 80 253);
  border-radius: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
`;

export const SECContainer = styled.div`
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 64rem;
  border: 2px solid rgb(177 80 253);
  border-radius: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const CONTWRAP = styled.div`
display: flex;
align-items: center;
justify-content: center;
align-content: center;
width: 100%;
@media (max-width: 565px) {
  flex-direction: column;
}
`;



export const CTContainertop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid rgb(177 80 253);
  border-radius: 1rem;
  padding-top: 2rem;

`;

export const CTContainertop2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-left: 2px solid rgb(177 80 253);
`;

export const CTContainertop3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const CTContainertopWraper = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: center;
align-content: center;
border-top: 2px solid rgb(177 80 253);
@media (max-width: 565px) {
  flex-direction: column;
}
`;

export const CTContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid rgb(177 80 253);
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const CTContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid rgb(177 80 253);
  border-left: 2px solid rgb(177 80 253);
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const CTContainer3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const CTContainer4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-left: 2px solid rgb(177 80 253);
  padding-top: 3rem;
  padding-bottom: 3rem;
`;


export const BTNContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export const Foot = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 100px 50px;
  @media (max-width: 565px) {
    flex-direction: column;
  }
`;

export const Socials = styled.div`
  text-decoration: none;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  width: 250px;
  height: 50px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
`;

export const FCont = styled.div`
display: flex;
flex-direction: column;
gap: 35px;
align-items: flex-start;
justify-content: center;
align-content: center;
@media (max-width: 565px) {
  margin-bottom: 88px;
}
`;
