import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, importDISPO } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import NftCard from "./components/nftcard";
import { InputNumber } from "rsuite";
import { Input, InputGroup, MaskedInput } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  font-family: "pp";
  background-color: #b150fe;
  padding: 10px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: white;
  width: 200px;
  cursor: pointer;
  box-shadow: -1px 1px 12px 0px #b150fe;
  -webkit-box-shadow: -1px 1px 12px 0px #b150fe;
  -moz-box-shadow: -1px 1px 12px 0px #b150fe;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #8e00ff;
  }
  @media (max-width: 565px) {
    width: 150px;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 590px) {
    flex-direction: column;
  }
`;

export const StyledImg = styled.img`
  width: 100px;
  height: 80px;
  @media (min-width: 900px) {
    width: 100px;
    height: 80px;
  }
  @media (min-width: 1000px) {
    width: 100px;
    height: 80px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #cecece;
  text-decoration: none;
`;

export const StyledHR = styled.hr`
  border: 2px solid white;
  background-color: white;
  border-radius: 2px;
  width: 400px;
  @media (max-width: 567px) {
    width: 250px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 60px;
  @media (max-width: 767px) {
    width: 60px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const ResponsiveWrapperTitles = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const ResponsiveWrapperButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const WalletBox = styled.div`
padding: 10px;
border-radius: 5px;
font-family: 'pp';
background-color: #b150fe;
padding: 10px;
font-size: 1.125rem;
line-height: 1.75rem;
font-weight: 500;
color: white;
width: 200px;
cursor: pointer;
box-shadow: -1px 1px 12px 0px #b150fe;
-webkit-box-shadow: -1px 1px 12px 0px #b150fe;
-moz-box-shadow: -1px 1px 12px 0px #b150fe;
:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
:hover {
  background-color: #8e00ff;
}
@media (max-width: 565px) {
  width: 150px;
}
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const { ethereum } = window;
  let web3 = new Web3(ethereum);
  const [walletAddress, setAddress] = useState("Connect Wallet");
  const [claimingNft, setClaimingNft] = useState(false);
  const [StakeNFT, setStakeNFT] = useState(false);
  const [UnStakeNFT, setUnStakeNFT] = useState(false);
  const [ClaimReward, setClaimReward] = useState(false);
  const [CheckReward, setCheckReward] = useState(false);
  const [CheckIDS, setCheckIDS] = useState(false);
  const [Border, setBorder] = useState(null);
  const [feedback, setFeedback] = useState(
    `Approve your wallet for staking. (You only need to do this once)`
  );
  const [StakeMSG, setMSG] = useState(`Stake All or Input Token ID and Stake`);
  const [UnStakeMSG, setUnMSG] = useState(
    `Unstake All or Input Token ID and Unstake`
  );
  const [HavestMSG, setHarvestMSG] = useState(`Claim Your DISPO`);
  const [RewardMSG, setRewardMSG] = useState(``);
  const [CheckMSG, setCheckMSG] = useState(
    `Click to Check Your Staked Medicated Mice`
  );
  const [tokens, settokens] = useState([1]);
  const tokensInputRef = useRef();
  const [tokenofowner, settokenofowner] = useState();
  const [StakedNFTS, setStakedNFTS] = useState(0);
  const [OLDStakedNFTS, setOLDStakedNFTS] = useState(0);
  const [tokenids, setTokenids] = useState(0);
  const [TID, setTID] = useState([]);
  const [totalrewards, settotalrewards] = useState(0);
  const [UserBalance, setUserBalance] = useState(0);
  const [Userinit, setUserinit] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    NEW_CONTRACT: "",
    TOKEN: "",
    NFT: "",
    Controller: "",
    SCAN_LINK: "",
    ENDPOINT: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    Opensea_LINK: "",
    Twitter_LINK: "",
    Instagram_LINK: "",
    Discord_LINK: "",
    SHOW_BACKGROUND: false,
  });

  ///

  const endpoint = CONFIG.ENDPOINT;

  const fetchNFTs = async (owner, contractAddress, setNFTs, retryAttempt) => {
    if (retryAttempt === 5) {
      return;
    }
    if (owner) {
      let data;
      try {
        if (contractAddress) {
          data = await fetch(
            `${endpoint}/getNFTs?owner=${owner}&contractAddresses%5B%5D=${contractAddress}`
          ).then((data) => data.json());
        } else {
          data = await fetch(`${endpoint}/getNFTs?owner=${owner}`).then(
            (data) => data.json()
          );
        }
      } catch (e) {
        fetchNFTs(endpoint, owner, contractAddress, setNFTs, retryAttempt + 1);
      }

      setNFTs(data.ownedNfts);
      return data;
    }
  };

  const [owner, setOwner] = useState(blockchain.account);
  const [contractAddress, setContractAddress] = useState(CONFIG.NFT);
  const [NFTs, setNFTs] = useState("");
  const BN = Web3.utils.toBN;
  ///

  const importDISPO = (async) => {
    ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0xC0F392CCe7a4Df620ae020C44081d04BED68cDFD",
            symbol: "DISPO",
            decimals: 18,
            image:
              "https://cdn.discordapp.com/attachments/945540648235335690/978509689627234324/logo-4_3.png",
          },
        },
      })
      .then((success) => {
        if (success) {
          console.log("DISPO successfully added to wallet!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .catch(console.error);
  };

  const Approve = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setFeedback(`Approving your Staking...`);
    setClaimingNft(true);
    blockchain.smartNFT.methods
      .setApprovalForAll(CONFIG.NEW_CONTRACT, true)
      .send({
        to: CONFIG.NFT,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`You can Now Stake Your NFT`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const InitUser = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setFeedback(`Initizialing your Old Staked NFTs...`);
    setClaimingNft(true);
    blockchain.smartNew.methods
      .initolduser(blockchain.account, OLDStakedNFTS)
      .send({
        to: CONFIG.NEW_CONTRACT,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log("User init:", receipt);
        setUserinit(true);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const Stake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setMSG(`Staking your ${CONFIG.SYMBOL} #${tokens}...`);
    setStakeNFT(true);
    blockchain.smartNew.methods
      .deposit([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setMSG("Sorry, Something Went Wrong");
        setStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setMSG(`You Successfully Staked Your ${CONFIG.NFT_NAME} #${tokens}`);
        setStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const StakeALL = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * StakedNFTS);
    console.log("Gas limit: ", totalGasLimit);
    setMSG(`Staking All of Your NFTs...`);
    setStakeNFT(true);
    blockchain.smartNFT.methods
      .walletOfOwner(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("NFTS of User:", receipt);
        let NFTSOFUSER = receipt;
        dispatch(fetchData(blockchain.account));
        blockchain.smartNew.methods
          .deposit(NFTSOFUSER)
          .send({
            to: CONFIG.NEW_CONTRACT,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setHarvestMSG(
              "Sorry, something went wrong please try again later."
            );
            setClaimReward(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setHarvestMSG(`All NFTs Staked`);
            setStakeNFT(false);
            dispatch(fetchData(blockchain.account));
          });
      });
  };

  const UnStake = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking your ${CONFIG.SYMBOL} #${tokens}...`);
    setUnStakeNFT(true);
    blockchain.smartNew.methods
      .withdraw([tokens])
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.NEW_CONTRACT,
        from: blockchain.account,
      })

      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, you have reached your wallet limit");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(`You Successfully UnStaked ${CONFIG.SYMBOL} #${tokens}`);
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const UnStakeALLOLD = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * TID.length);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking All of Your NFTs...`);
    setUnStakeNFT(true);
    blockchain.smartContract.methods
      .unstake(OLDStakedNFTS)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, something went wrong");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(`You Successfully UnStaked All of your NFTs`);
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const UnStakeALL = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * TID.length);
    console.log("Gas limit: ", totalGasLimit);
    setUnMSG(`UnStaking All of Your NFTs...`);
    setUnStakeNFT(true);
    blockchain.smartNew.methods
      .withdraw(TID)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.NEW_CONTRACT,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setUnMSG("Sorry, something went wrong");
        setUnStakeNFT(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setUnMSG(`You Successfully UnStaked All of your NFTs`);
        setUnStakeNFT(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const Harvest = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    setHarvestMSG(`Claiming your Rewards...`);
    setClaimReward(true);
    blockchain.smartNew.methods
      .depositsOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log(receipt);
        setCheckMSG(`TokenID #${receipt}`);
        let TID = receipt;
        setCheckIDS(false);
        dispatch(fetchData(blockchain.account));
        blockchain.smartNew.methods
          .claimRewards(TID)
          .send({
            to: CONFIG.NEW_CONTRACT,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setHarvestMSG(
              "Sorry, something went wrong please try again later."
            );
            setClaimReward(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setHarvestMSG(`Rewards Harvested`);
            setClaimReward(false);
            dispatch(fetchData(blockchain.account));
          });
      });
  };

  const RewardCheck = () => {
    setRewardMSG(`Checking your ${CONFIG.SYMBOL} #${tokens} Rewards...`);
    setCheckReward(true);
    blockchain.smartNew.methods
      .calculateRewards(blockchain.account, TID)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        let WEIFORM = Web3.utils.fromWei;
        let recp = WEIFORM(receipt[0].toString(), "ether").substring(0, 6);
        let allrewards = recp * StakedNFTS;
        console.log("Allrewards:", allrewards, "DISPO");
        settotalrewards(allrewards);
        setRewardMSG(`$ ${totalrewards}`);
        setCheckReward(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const CheckNFTs = () => {
    setCheckMSG(`Checking your Staked Tokens...`);
    setCheckIDS(true);
    blockchain.smartNew.methods
      .depositsOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("StakedNFTs:", receipt);
        setStakedNFTS(receipt.length);
        setTokenids(receipt);
        setTID(receipt.map(Number));
        setCheckIDS(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const CheckNFTsOLD = () => {
    setCheckMSG(`Checking your Staked Tokens...`);
    setCheckIDS(true);
    blockchain.smartNew.methods
      .depositsOfold()
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("OLD StakedNFTs:", receipt);
        setOLDStakedNFTS(receipt);
        setCheckIDS(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const CheckUserTokens = () => {
    blockchain.smartToken.methods
      .balanceOf(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        let balanceofuser = Web3.utils
          .fromWei(receipt.toString(), "ether")
          .substring(0, 6);
        setUserBalance(balanceofuser);
        console.log("Balance Of User:", balanceofuser);
        dispatch(fetchData(blockchain.account));
      });
  };

  const CheckUserinit = () => {
    blockchain.smartNew.methods
      .userinit(blockchain.account)
      .call({
        from: blockchain.account,
      })
      .then((receipt) => {
        console.log("User init:", receipt);
        setUserinit(receipt);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 4) +
          "..." +
          blockchain.account.substring(38, 42)
      );
      fetchNFTs(blockchain.account, CONFIG.NFT, setNFTs);
      CheckNFTs();
      CheckNFTsOLD();
      CheckUserinit();
      RewardCheck();
      CheckUserTokens();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapperHeader>
          <Zoom top>
            <s.HeaderDiv>
              <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
              <s.TEXTHEAD>BABY MEDICATED MICE</s.TEXTHEAD>
            </s.HeaderDiv>
          </Zoom>
          <Zoom top>
            <s.Headerlinks>
              <s.StyledLink href="#" target={"_blank"}>
                ABOUT
              </s.StyledLink>
              <s.StyledLink
                href="https://mint.medicatedmice.co/"
                target={"_blank"}
              >
                HOW TO BUY
              </s.StyledLink>
              <s.StyledLink
                href="https://www.medicatedmice.co/#roadmap"
                target={"_blank"}
              >
                ROADMAP
              </s.StyledLink>
              <s.StyledLink
                href="https://www.medicatedmice.co/#team"
                target={"_blank"}
              >
                TEAM
              </s.StyledLink>
              <s.StyledLink
                href="https://www.medicatedmice.co/#faq"
                target={"_blank"}
              >
                FAQ
              </s.StyledLink>
              <s.StyledLink
                href="https://babystake.medicatedmice.co/"
                target={"_blank"}
              >
                LOOKING FOR OG MICE STAKING?
              </s.StyledLink>
            </s.Headerlinks>
          </Zoom>
          <Zoom top>
            <s.HeaderDiv>
              <a href={CONFIG.Twitter_LINK} target={"_blank"}>
                <s.Icons src="/config/images/twitter.png" alt="twitter" />
              </a>
              <a href={CONFIG.Instagram_LINK} target={"_blank"}>
                <s.Icons src="/config/images/insta.png" alt="insta" />
              </a>
              <a href={CONFIG.Discord_LINK} target={"_blank"}>
                <s.Icons src="/config/images/discord.png" alt="discord" />
              </a>
              <a href={CONFIG.Opensea_LINK} target={"_blank"}>
                <s.Icons src="/config/images/opensea.svg" alt="opensea" />
              </a>

              <WalletBox
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                {blockchain.account !== "" ? (
                  <>
                    <s.TextSubTitle
                      style={{ fontSize: "1rem", color: "white" }}
                    >
                      {walletAddress}
                    </s.TextSubTitle>
                  </>
                ) : null}
              </WalletBox>
            </s.HeaderDiv>
          </Zoom>
        </ResponsiveWrapperHeader>

        <ResponsiveWrapper>
          <s.MContainer>
            <s.TextDescription>
              <p>
                {" "}
                <b>BABY MICE STAKING PAGE</b>{" "}
              </p>
              <p>
                Stake your Medicated Mice and earn lucrative benefits such as
                Dispensary Vouchers, Exclusive Giveaways and Alpha Tier
                Whitelisting. More packages will be available soon!
              </p>

              <p>Staking Payouts:</p>
              <p>
                <u>OG Mice</u> | 100x <b>$DISPO</b> / day
              </p>
              <p>
                <u>Baby Mice</u> | 200x <b>$DISPO</b> / day
              </p>
            </s.TextDescription>
            <s.SpacerXSmall></s.SpacerXSmall>
            <StyledButton
              onClick={(e) => {
                importDISPO();
              }}
            >
              <s.TextDescription>Import $DISPO</s.TextDescription>
            </StyledButton>
            <s.SpacerMedium />
            <StyledButton
              onClick={(e) => {
                e.preventDefault();
                Approve();
              }}
            >
              {claimingNft ? "Approving..." : "Approve"}
            </StyledButton>
            <s.SpacerMedium />
            <s.TextDescription>{feedback}</s.TextDescription>
            <s.SpacerMedium />
            {Userinit === false ? (
              <>
                <s.TextDescription style={{ fontFamily: "Montserrat" }}>
                If you Staked in the old staking contract, please use the "Initialize"
                  function to accumulate your $DISPO and migrate your staked mice.
                </s.TextDescription>
                <s.SpacerMedium />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    InitUser();
                  }}
                >
                  {claimingNft ? "Initializing..." : "Initialize"}
                </StyledButton>
                <s.SpacerMedium />
              </>
            ) : (
              <></>
            )}

            <s.CTContainertop>
              <s.TextTitle2>Your Earn Rate: {StakedNFTS * 200} $DISPO / Day</s.TextTitle2>
              <s.SpacerMedium />

              <s.CTContainertopWraper>
                <s.CTContainertop2>
                  <s.TextTitle2>Your $</s.TextTitle2>
                  <s.SpacerSmall />
                  <s.TextTitle2>{UserBalance}</s.TextTitle2>
                  <s.SpacerSmall />
                  <StyledButton
                    disabled={ClaimReward ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      CheckUserTokens();
                      getData();
                    }}
                  >
                    Check Balance
                  </StyledButton>
                  <s.SpacerSmall />
                </s.CTContainertop2>

                <s.CTContainertop2>
                  <s.TextTitle2>Claimable $</s.TextTitle2>
                  <s.SpacerSmall />
                  <s.TextTitle2>{totalrewards}</s.TextTitle2>
                  <s.SpacerSmall />
                  <StyledButton
                    disabled={ClaimReward ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      Harvest();
                      getData();
                    }}
                  >
                    {ClaimReward ? "Claiming..." : "Claim"}
                  </StyledButton>
                  <s.SpacerSmall />
                  <s.TextSubTitle>{HavestMSG}</s.TextSubTitle>
                </s.CTContainertop2>
              </s.CTContainertopWraper>
            </s.CTContainertop>
          </s.MContainer>

          <s.SECContainer>
            <s.CONTWRAP>
              <s.CTContainer>
                <s.TextTitle2>NON-STAKED MEDICATED MICE</s.TextTitle2>
                <s.SpacerMedium />
                <s.BTNContainer>
                  <StyledButton
                    disabled={StakeNFT ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      StakeALL();
                      getData();
                    }}
                  >
                    {StakeNFT ? "Staking All..." : "Stake All"}
                  </StyledButton>
                  <StyledButton
                    disabled={StakeNFT ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      Stake();
                      getData();
                    }}
                  >
                    {StakeNFT ? "Staking..." : "Stake Selected"}
                  </StyledButton>
                </s.BTNContainer>
                <s.SpacerSmall />
                <s.TextSubTitle>{StakeMSG}</s.TextSubTitle>
                <s.SpacerMedium />
                <InputNumber
                  onChange={(e) => {
                    settokens(e);
                  }}
                  ref={tokensInputRef}
                  max={10000}
                  min={1}
                  size={"sm"}
                  placeholder={"TokenID"}
                  style={{ width: 300, fontFamily: "pp" }}
                />
                <s.SpacerMedium />
              </s.CTContainer>

              <s.CTContainer2>
                <s.TextTitle2>STAKED MEDICATED MICE</s.TextTitle2>
                <s.SpacerMedium />
                <s.BTNContainer>
                  <StyledButton
                    disabled={UnStakeNFT ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      UnStakeALL();
                      getData();
                    }}
                  >
                    {UnStakeNFT ? "Unstaking All..." : "Unstake All"}
                  </StyledButton>
                  <StyledButton
                    disabled={UnStakeNFT ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      UnStake();
                      getData();
                    }}
                  >
                    {UnStakeNFT ? "Unstaking..." : "Unstake Selected"}
                  </StyledButton>
                </s.BTNContainer>
                <s.SpacerSmall />
                <s.TextSubTitle>{UnStakeMSG}</s.TextSubTitle>
                <s.SpacerMedium />
                <StyledButton
                  disabled={UnStakeNFT ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    UnStakeALLOLD();
                    getData();
                  }}
                >
                  {UnStakeNFT ? "Unstaking All..." : "Unstake All From Old Contract"}
                </StyledButton>
                <s.SpacerMedium />
                <InputNumber
                  onChange={(e) => {
                    settokens(e);
                  }}
                  ref={tokensInputRef}
                  max={10000}
                  min={1}
                  size={"sm"}
                  placeholder={"TokenID"}
                  style={{ width: 300, fontFamily: "pp" }}
                />
                <s.SpacerMedium />
              </s.CTContainer2>
            </s.CONTWRAP>
            <s.CONTWRAP>
              <s.CTContainer3>
                <s.TextTitle2>Non-Staked NFTs:</s.TextTitle2>
                <s.IMGCT>
                  {NFTs ? (
                    NFTs.map((NFT) => {
                      let Tok = NFT.id.tokenId;
                      let newtok = BN(Tok).toString();
                      return <NftCard id={newtok}></NftCard>;
                    })
                  ) : (
                    <s.TextDescription>No NFTs found</s.TextDescription>
                  )}
                </s.IMGCT>
              </s.CTContainer3>

              <s.CTContainer4>
                <s.TextTitle2>Staked NFTS:</s.TextTitle2>
                <s.IMGCT>
                  {tokenids ? (
                    tokenids.map((tokenids) => {
                      return <NftCard id={tokenids}></NftCard>;
                    })
                  ) : (
                    <s.TextDescription>No NFTs Staked</s.TextDescription>
                  )}
                </s.IMGCT>
              </s.CTContainer4>
            </s.CONTWRAP>
          </s.SECContainer>

          <s.Foot>
            <s.FooterDiv>
              <s.HeaderDiv>
                <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
                <s.TEXTHEAD>
                  MEDICATED <span style={{ color: "#b150fe" }}>MICE</span>
                </s.TEXTHEAD>
              </s.HeaderDiv>
              <s.TextDescription style={{ textAlign: "left", width: "70%" }}>
              In early 2022, a large group of anthropomorphous Mice will swarmed Earth in hopes to find a new source of happiness, cannabis.
              </s.TextDescription>
            </s.FooterDiv>
            <s.SpacerXSmall />

            <s.FCont>
              <s.TextTitle>Quick Links</s.TextTitle>
              <s.SpacerMedium />
              <s.Socials>
          <s.StyledLinkFooter href="#" target={"_blank"}>
              ABOUT
            </s.StyledLinkFooter >
            <s.StyledLinkFooter href="https://mint.medicatedmice.co/" target={"_blank"}>
              HOW TO BUY
              </s.StyledLinkFooter>
              <s.StyledLinkFooter href="https://www.medicatedmice.co/#roadmap" target={"_blank"}>
              ROADMAP
              </s.StyledLinkFooter>
              <s.StyledLinkFooter href="https://www.medicatedmice.co/#team" target={"_blank"}>
              TEAM
              </s.StyledLinkFooter>
              <s.StyledLinkFooter href="https://www.medicatedmice.co/#faq" target={"_blank"}>
              FAQ
              </s.StyledLinkFooter>
          </s.Socials>
            </s.FCont>

            <s.FCont>
              <s.TextTitle>Follow US</s.TextTitle>
              <s.SpacerMedium />
              <s.Socials>
                <a
                  href={CONFIG.Twitter_LINK}
                  target={"_blank"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "pp",
                    color: "white",
                  }}
                >
                  <s.Icons src="/config/images/twitterw.png" alt="twitter" />{" "}
                  TWITTER
                </a>
                <a
                  href={CONFIG.Instagram_LINK}
                  target={"_blank"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "pp",
                    color: "white",
                  }}
                >
                  <s.Icons src="/config/images/instaw.png" alt="insta" />{" "}
                  INSTAGRAM
                </a>
                <a
                  href={CONFIG.Discord_LINK}
                  target={"_blank"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "pp",
                    color: "white",
                  }}
                >
                  <s.Icons src="/config/images/discordw.png" alt="discord" />{" "}
                  DISCORD
                </a>
                <a
                  href={CONFIG.Opensea_LINK}
                  target={"_blank"}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "pp",
                    color: "white",
                  }}
                >
                  <s.Icons src="/config/images/openseaw.svg" alt="opensea" />{" "}
                  OPENSEA
                </a>
              </s.Socials>
            </s.FCont>
          </s.Foot>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
