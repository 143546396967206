import { useState } from "react"

const NftCard = ({ image, id }) => {
    return (
        <div className="w-1/4 mr-3 mb-4 bg-slate-100 rounded-md"
        >
            <img width={80} height={80} className='w-full rounded-t-md' key={id} src={`https://opensea.mypinata.cloud/ipfs/QmWM8MoGqG3VGhXwGyhZBtDrfxJFgHiq8Uhiw1yGWwpNhm/${id}.png`}></img>
            <div className="p-3">
                <div className="flex mb-3">
                    <div className="flex-grow">
                        <p style={{textAlign: "center", fontFamily: 'pp', color: 'white'}}>
                        {`#${id}`}
                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NftCard